import React from "react"
import { Link } from "gatsby"
import support from "../../images/support.png"
import support1 from "../../images/support_bg.jpg"
import { useSelector } from "react-redux"

const OurTeam = () => {
  const { language } = useSelector(state => state.global)
  const { leaders } = useSelector(state => state.about)
  return (
    <section className="leader_set">
      <div className="container">
        <div className="row text_side">
          <div className="txt_block">
            <div className="lftbrdr">
              <h6>{leaders.title[language]}</h6>
              <h2>{leaders.text[language]} </h2>
            </div>
          </div>
          <div className="para_block">
            <p>{leaders.para[language]}</p>
          </div>
        </div>
        <div className="team_block">
          {leaders.list &&
            leaders.list.map(team => (
              <div className="team_box">
                <img src={team.img.sourceUrl || ""} alt={team.name} />
                <div className="title">
                  <h3>{team.name}</h3>
                  {/* <span>{team.position[language]}</span> */}
                </div>
              </div>
            ))}
          <div className="team_box">
            <Link to="/careers">
              <img src={support1} alt="webura-join_the_movement" />
              <div className="support_txt">
                <img
                  src={support}
                  className="support d-block m-auto"
                  alt="support"
                />
                <h4>{leaders.movement[language]}</h4>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurTeam
